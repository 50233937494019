import React from 'react';
import PropTypes from 'prop-types';
import Search from '../Search';
// import SignUp from '../Auth/SignUp';
// import AccountOptions from './AccountOptions';
import Link from 'next/link';

import { throttle } from '../../lib/debounce';
import { Col, Row } from 'antd';
import { spacing, fontSize, minScreenSize, maxScreenSize, colors } from '../../lib/theme';
import { domainName, staticAssetsPrefix, topBarPremiumHeight } from '../../lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import AppCustomLink from '../Common/AppCustomLink';
import * as gtag from '../../lib/gtag';
// const TopBar = () => (
class TopBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = { searchValue: '', scrolledDown: false, isMobile: false };
    this.handleScroll = throttle(this.handleScroll, 200);
  }

  componentDidMount() {
    // window.addEventListener('scroll', this.handleScroll);

    if (window.innerWidth < 700) { // less than tablet
      this.setState({ isMobile: true });
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll);
  }

  // handleScroll = () => {
  //   const { scrolledDown } = this.state;
  //   let scrollTop = window.scrollY; // event.srcElement.body.scrollTop;
  //   if (scrollTop > 20) {
  //     if (!scrolledDown) {
  //       this.setState({ scrolledDown: true });
  //     }
  //   } else {
  //     if (scrolledDown) {
  //       this.setState({ scrolledDown: false });
  //     }
  //   }
  //   // let itemTranslate = Math.min(0, scrollTop / 3 - 60);
  //   // let scrollTop = event.srcElement.body.scrollTop,
  //   //     itemTranslate = Math.min(0, scrollTop/3 - 60);

  //   // this.setState({
  //   //   transform: itemTranslate
  //   // });
  // }

  // goBack = () => {
  //   window.history.back();
  // }

  // goForward = () => {
  //   window.history.forward();
  // }

  handleChange = (e) => {
    if (e.key !== 'Enter') {
      console.log('dont validate');
      this.setState({ searchValue: e.target.value });
    }
  }

  searchPodcast = () => {
    window.open(`${domainName}search-all?text=${this.state.searchValue}`, '_self');
    // Object.assign(document.createElement('a'), { href: `${domainName}search/${this.state.searchValue}` }).click();
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('do validate');
      this.searchPodcast();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  render() {
    // const { userDetails } = this.props;
    // const { loadingUserDetails } = this.props.loadingState;
    // const { leftBarColor } = this.props.settingsState;
    // const leftBarColor = 'lightgray';

    const { scrolledDown, isMobile } = this.state;
    // const bgColor = scrolledDown ? `${leftBarColor}` : 'none';
    const scrollClass = scrolledDown ? 'scrollClass' : '';

    // console.dir(this.props);
    return (
      <div className={'padding ' + scrollClass}>
        <nav>
          <Row className='nav-main'>
            {/* <Col xs={0} xl={2} style={{ 'marginTop': `${spacing('xs')}`, color: `${colors.primary}` }}>
            <Icon type="left" onClick={this.goBack} style={{ 'fontSize': `${fontSize('lg')}` }} />&nbsp;
            <Icon type="right" onClick={this.goForward} style={{ 'fontSize': `${fontSize('lg')}` }} />
          </Col> */}
            <Col xs={12} md={5} xl={3}>
              {/* <Link href='/' > */}
              {/* <span className='footer-link'>Legal</span> */}
              <div style={{ display: 'flex', alignItems: 'center' }} >
                {/* <img src={`${staticAssetsPrefix}/static/images/main-page/landing.svg`} /> */}
                <img style={{ width: '2.25em' }} className='logo-img' src={`${staticAssetsPrefix}/static/images/Logo.png`} alt='Podurama logo' />
                <h2 className='quicksand' style={{ color: 'black', fontWeight: '700', marginBottom: '0' }}>&nbsp;Podurama</h2>
              </div>
              {/* </Link> */}
            </Col>
            <Col xs={10} md={8} xl={7}>
              {/* <Search /> */}
              {/* Search */}
              <div id="searchwrapper">
                <form onSubmit={this.handleSubmit}>
                  <input type="text" className="searchbox quicksand" placeholder={isMobile ? 'Search' : 'Search over 900,000 podcasts'}
                    onKeyDown={this._handleKeyDown}
                    value={this.state.searchValue} onChange={this.handleChange} />
                  {/* <input type="image" src="THE_BLANK_SUBMIT_BUTTON_IMAGE" className="searchbox_submit" value="" /> */}
                  {/* <button type='primary' className="searchbox_submit" onClick={this.searchPodcast} style={{ borderRadius: `${spacing()}`, backgroundColor: `${colors.primary}` }}> */}
                  <a className="searchbox_submit" onClick={this.searchPodcast} style={{}}>
                    <FontAwesomeIcon icon='search' width="16" style={{ fontSize: '1rem', color: 'darkgray' }} />
                  </a>
                  {/* </button> */}
                </form>
              </div>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 4, offset: 2 }} xl={{ span: 4, offset: 4 }}>
              <Row>
                {/* <li>
                <a href={`${domainName}`}>Home</a>
              </li> */}
                <Col md={12}>
                  <li className='hide-till-laptop  white-link'>
                    <Link href={`/featured`}><a className='serif' onMouseDown={() => gtag.basicEvent('featured-lists-click')}>Featured</a></Link>
                  </li>
                </Col>
                <Col md={8}>
                  <li className='hide-till-laptop white-link'>
                    <Link href={`/ads`}><a className='serif'>Ads</a></Link>
                  </li>
                </Col>
                {/* <li className='hide'>
                <a href={`${domainName}home`}>Web Player</a>
              </li> */}
              </Row>
            </Col>
            {/* <Col xs={8} md={6} xl={6}> */}
            <Col xs={0} md={5} xl={6}>
              <Row>
                <Col span={24}>
                  {/* <li className='hide btn-common white-link-register app-btn-primary' style={{  }}> */}
                  <a className='hide-till-tablet btn-common white-link-register app-btn-primary serif' href={`${domainName}login?type=signUp`}>Register</a>
                  {/* </li> */}
                  {/* <li className='hide-till-tablet btn-common' style={{ color: `${colors.third}` }}> */}
                  <a className='hide-till-tablet btn-common app-btn-outline-white serif' style={{}} href={`${domainName}login`}>Login</a>
                  {/* </li> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </nav>
        <style jsx>
          {`
            :global(.nav-main) {
              display: flex;
              align-items: center;
            }
            .btn-common {
              padding: 0.35em 0.75em;
              width: 100px;
              text-align: center;
              border-radius: 0.25em;
              // border: 1px solid;
              // border-color: ${colors.third};
              margin-right: 1em;
              cursor: pointer;
            }
            .app-btn-outline-white {
              color: #030028 !important;
              // border: 1px solid;
              border-color: white;
            }
            .padding{
              padding: ${spacing('md')};
              padding-top: 0.6em; // ${spacing('')};
              padding-left: ${spacing('xl')};
              padding-right: ${spacing('xl')};
              // position: fixed;
              // width: 100vw;
              z-index: 10;
              top: ${topBarPremiumHeight};
              background-color: white;
              background: none;
              transition: background-color 0.1s linear;
              height: 56px;
              // background: black; // hsla(235.9, 33.7%,22.2%, 1); // white;
              position: absolute;
              z-index: 10;
              width: 99vw;
            }
            .scrollClass {
              background: black; // hsla(235.9, 33.7%,22.2%, 1); // white;
              box-shadow: 0 4px 4px -2px rgba(0,0,0,.2);
              opacity: 0.9;
            }
            li {
              width: 96px;
              display: inline-block;
              text-align: center;
            }
            .white-link-register a {
              color: white !important;
            }
            .white-link a {
              color: #030028 !important;
            }
            .white-link a:hover {
              color: ${colors.third} !important; // #4E7CFF !important;
            }
            // a:hover {
            //   color: ${colors.third} !important;
            // }
            .logo-img {
              width: 55%;
            }
            @media ${maxScreenSize.laptop} {
              .padding{
                width: 100vw;
                // padding-left: ${spacing('xs')};
              }
            }
            #searchwrapper {
              // width:360px; /*follow your image's size*/
              height:40px;/*follow your image's size*/
              // background-image:url(THE_SEARCH_BOX_IMAGE);
              // background-repeat:no-repeat; /*important*/
              padding:0px;
              margin:0px;
              position:relative; /*important*/
            }
              
            #searchwrapper form { display:inline ; }
              
            .searchbox {
              border: 1px solid ${colors.primaryDim}; /*important*/
              border-radius: 1.5em;
              // background-color:transparent; /*important*/
              position:absolute; /*important*/
              top:4px;
              left:9px;
              width: 360px;
              height:2.5em;
              padding: 0 1em;
            }
            input:focus {
              outline: none;
            }
              
            .searchbox_submit {
              border:0px; /*important*/
              background-color:transparent; /*important*/
              position:absolute; /*important*/
              top:4px;
              left: 320px;
              // right: 8px;
              // width:32px;
              height:2em;
              margin-top: 8px;
            }
            @media ${maxScreenSize.tablet} {
              .searchbox {
                width: 160px;
              }
              .searchbox_submit {
                left: 120px;
              }
              .logo-img {
                width: 100%;
              }
              li {
                text-align: left;
              }
              .padding {
                padding: ${spacing('xs')} ${spacing('')};
              }
              .hide-till-tablet{
                display: none;
              }
            }
            @media ${minScreenSize.tablet} and ${maxScreenSize.laptop} {
              .searchbox{
                width: 280px;
              }
              .searchbox_submit {
                left: 240px;
              }
              .logo-img {
                width: 85%;
              }
            }
            @media ${maxScreenSize.laptop} {
              .hide-till-laptop{
                  display: none;
              }
            }
          `}
        </style>
      </div>
    )
  }
}

TopBar.propTypes = {
  // loadingUserDetails: PropTypes.bool,
  // userDetails: PropTypes.object,
  // loadingState: PropTypes.object,
  // settingsState: PropTypes.object,
}

// const mapStateToProps = (state) => {
//   return {
//     // userDetails: state.userDetails,
//     // loadingState: state.loadingState,
//     // settingsState: state.settingsState,
//     // loadingUserDetails: state.loadingState.loadingUserDetails,
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//   }
// };

// export default connect(null, null)(TopBar);
export default TopBar;