export const GA_TRACKING_ID = 'UA-140492493-1';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'virtualPageView',  //fixed value you need to use as trigger in GTM
        'virtualPagePath': url,
        // virtualPageTitle: title
    });

    // window.gtag('config', GA_TRACKING_ID, {
    //     page_path: url
    // })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const basicEvent = (action) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': action,  //fixed value you need to use as trigger in GTM
    });
    // window.gtag('event', action);
}
export const logEvent = (eventName, eventParameters) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': eventName,  //fixed value you need to use as trigger in GTM
        ...eventParameters
    });

    // window.gtag('event', action, {
    //     event_category: category,
    //     event_label: label,
    //     value: value
    // })
}
export const event = ({ action, category, label, value }) => {
    window.dataLayer = window.dataLayer || [];
    // window.gtag('event', action, {
    //     event_category: category,
    //     event_label: label,
    //     value: value
    // })
}