import React from 'react';
import PropTypes from 'prop-types';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import Search from '../Search';
// import SignUp from '../Auth/SignUp';
// import AccountOptions from './AccountOptions';
// import AddCustomRSS from '../Podcast/AddCustomRSS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import AppCustomLink from '../Common/AppCustomLink';
import Link from 'next/link';
import { staticAssetsPrefix, domainName, topBarPremiumHeight } from '../../lib/utils';
import { toggleLeftBar } from '../../actions';
import { throttle } from '../../lib/debounce';
// import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { spacing, fontSize, maxScreenSize, minScreenSize, colors } from '../../lib/theme';
// const TopBar = () => (
class TopBarArticle extends React.Component {

  constructor(props) {
    super(props);
    this.state = { scrolledDown: false, isMobile: false };
    this.handleScroll = throttle(this.handleScroll, 200);
  }

  componentDidMount() {
    if (window.innerWidth < 700) { // less than tablet
      this.setState({ isMobile: true });
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { isArticlePage } = this.props;
    // if (!isArticlePage) {
    let scrollTop = window.scrollY; // event.srcElement.body.scrollTop;
    if (scrollTop > 20) {
      this.setState({ scrolledDown: true });
    } else {
      this.setState({ scrolledDown: false });
    }
    // }
    // let itemTranslate = Math.min(0, scrollTop / 3 - 60);
    // let scrollTop = event.srcElement.body.scrollTop,
    //     itemTranslate = Math.min(0, scrollTop/3 - 60);

    // this.setState({
    //   transform: itemTranslate
    // });
  }

  goBack = () => {
    window.history.back();
  }

  goForward = () => {
    window.history.forward();
  }

  toggleLeftBar = () => {
    // put in redux
    this.props.toggleLeftBar();
  }

  handleChange = (e) => {
    if (e.key !== 'Enter') {
      console.log('dont validate');
      this.setState({ searchValue: e.target.value });
    }
  }

  searchPodcast = () => {
    window.open(`${domainName}search-all?text=${this.state.searchValue}`, '_self');
    // Object.assign(document.createElement('a'), { href: `${domainName}search/${this.state.searchValue}` }).click();
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('do validate');
      this.searchPodcast();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  render() {
    const { showNavigator, isArticlePage } = this.props;
    // const { loadingUserDetails } = this.props.loadingState;
    // const { leftBarColor } = this.props.settingsState;
    // const leftBarColor = 'lightgray';

    const { scrolledDown, isMobile } = this.state;
    // const bgColor = scrolledDown ? `${leftBarColor}` : 'none';
    const scrollClass = scrolledDown ? 'scrollClass' : '';
    // console.dir(this.props);
    return (
      <nav className={'padding ' + scrollClass}>
        <Row className='top-bar-row'>
          <Col xs={0} md={0} xl={0} >
            <p className='toggle-button' onClick={this.toggleLeftBar}>
              <FontAwesomeIcon className='bars-icon' icon='bars' size='lg' />
            </p>
          </Col>
          {isArticlePage && <Col xs={12} md={6} xl={4} style={{}}> <Link href='/' >
            {/* <span className='footer-link'>Legal</span> */}
            <a style={{ display: 'flex', alignItems: 'center' }} >
              {/* <img src={`${staticAssetsPrefix}/static/images/main-page/landing.svg`} /> */}
              <img style={{ width: '2.25em' }} className='logo-img' src={`${staticAssetsPrefix}/static/images/Logo.png`} alt='Podurama logo' />
              <h2 className='dark' style={{ fontWeight: '700', marginBottom: '0' }}>&nbsp;Podurama</h2>
            </a>
          </Link>
          </Col>}
          <Col xs={0} md={7} xl={10}>
            {/* <Search /> */}
            <div id="searchwrapper">
              <form onSubmit={this.handleSubmit}>
                <input type="text" className="searchbox" placeholder={isMobile ? 'Search' : 'Search over 900,000 podcasts'}
                  onKeyDown={this._handleKeyDown}
                  value={this.state.searchValue} onChange={this.handleChange} />
                {/* <input type="image" src="THE_BLANK_SUBMIT_BUTTON_IMAGE" className="searchbox_submit" value="" /> */}
                {/* <button type='primary' className="searchbox_submit" onClick={this.searchPodcast} style={{ borderRadius: `${spacing()}`, backgroundColor: `${colors.primary}` }}> */}
                <a className="searchbox_submit" onClick={this.searchPodcast} style={{}}>
                  <FontAwesomeIcon icon='search' width="16" style={{ fontSize: '1rem', color: '#03002877' }} />
                </a>
                {/* </button> */}
              </form>
            </div>
          </Col>
          {/* <Col xs={{ span: 0, offset: 0 }} md={{ span: 4, offset: 2 }} xl={{ span: 4, offset: 0 }}>
            <Row> */}
          <Col xs={0} md={0} xl={2}>
            <div className='hide-till-laptop white-link'>
              {/* <AppCustomLink href={`/`}><a>Home</a></AppCustomLink> */}
              <Link href={`/home`}><a>Web Player</a></Link>
            </div>
          </Col>
          {/* <Col xs={0} md={0} xl={2}>
            <div className='hide-till-laptop white-link'>
              <AppCustomLink href={`/blog`}><a>Blog</a></AppCustomLink>
            </div>
          </Col> */}
          <Col xs={{ span: 6, offset: 0 }} md={{ span: 2, offset: 3 }} xl={{ span: 2, offset: 0 }}>
            <div className='hide-till-laptop  white-link'>
              <Link href={`/featured`}><a>Featured</a></Link>
            </div>
          </Col>
          {/* </Row>
          </Col> */}
          <Col xs={{ span: 6, offset: 0 }} md={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 0 }} style={{ textAlign: 'center' }}>
            {/* <SignUp key='signUp' typeId='signUp' /> */}
            <div style={{ height: '4px' }}></div>
            <a className='hide-till-tablet btn-common white-link-register app-btn-primary serif' href={`${domainName}login?type=signUp`}>
              Register</a>
          </Col>
          {/* <Col xs={0} md={4} xl={4} style={{ textAlign: 'center' }}>
                <SignUp key='signIn' typeId='signIn' />
              </Col> */}
        </Row>
        <style jsx>
          {`
            .btn-common {
              padding: 6px 8px;
              border-radius: 4px;
            }
            .padding{
              padding: ${spacing('md')};
              padding-left: 5%; // ${isArticlePage ? '20em' : spacing('xl')};
              padding-right: 5%; // ${isArticlePage ? '20em' : spacing('xl')};
              position: fixed;
              width: ${isArticlePage ? '100vw' : '70vw'};
              z-index: 10;
              top: ${topBarPremiumHeight};
              background-color: white;
              transition: background-color 0.1s linear;
              height: 64px;
              border-bottom: 1px solid ${colors.primaryDim};
            }
            .white-link {
              text-align: center;
              margin-top: 6px;
            }
            .white-link a {
              color: #030028;
              font-family: 'PT Serif', serif;
              // font-
            }
            .white-link a:hover {
              color: ${colors.third};
            }
            .scrollClass {
              background: white;
              box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
            }
            .toggle-button {
              text-align: center;
              margin-top: 6px;
            }
            :global(.ant-btn-primary) {
              background: ${colors.primary};
              border-color: ${colors.primary};
            }
            :global(.third-button) {
                background: ${colors.third};
                border-color: ${colors.third};
                color: white;
            }
            :global(.third-button.outline) {
                background: white; // ${colors.third};
                border-color: ${colors.third};
                color: ${colors.third};
            }
            :global(.third-button:hover) {
                background: #8d2256;
                border-color: #8d2256;
                color: white;
            }
            :global(.auth-modal .ant-modal-content) {
              width: 60vw;
              transform: translate(-9vw,0);
              // height: 50vh;
            }
            :global(.auth-modal .ant-form-item-label) {
                text-align: left;
            }
            .searchbox {
              border: none; // 1px solid ${colors.primaryDim}; /*important*/
              background: hsla(223.9, 63.7%, 62.2%, 0.1);
              border-radius: 1.5em;
              // background-color:transparent; /*important*/
              position:absolute; /*important*/
              // top:4px;
              // left:9px;
              width: 360px;
              height:2.5em;
              padding: 0 1em;
            }
            input:focus {
              outline: none;
            }
            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #03002877;
              opacity: 1; /* Firefox */
            }
            :-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: #03002877;
            }
            ::-ms-input-placeholder { /* Microsoft Edge */
              color: #03002877;
            }
              
            .searchbox_submit {
              border:0px; /*important*/
              background-color:transparent; /*important*/
              position:absolute; /*important*/
              top:4px;
              left: 320px;
              // right: 8px;
              // width:32px;
              // height:2em;
              margin-top: 4px;
              color: #03002877;
            }
              
            @media ${maxScreenSize.tablet} {
              :global(.auth-modal .ant-modal-content) {
                  width: 95vw;
                  left: 10vw;
                  // transform: translate(-9vw,0);
                  // height: 50vh;
              }
              :global(.top-bar-row) {
                width: 95vw;
              }
              .searchbox {
                width: 160px;
              }
              .searchbox_submit {
                left: 120px;
              }
            }
              
            @media ${minScreenSize.tablet} and ${maxScreenSize.laptop} {
                .searchbox{
                  width: 280px;
                }
                .searchbox_submit {
                  left: 240px;
                }
                :global(.auth-modal .ant-modal-content) {
                    width: 70vw;
                    left: 10vw;
                }
                .padding{
                  padding-right: ${isArticlePage ? '2em' : spacing('xl')};
                }
            }
            @media ${maxScreenSize.laptop} {
              .padding{
                width: 100vw;
                padding-left: ${spacing('xs')};
              }
            }
          `}
        </style>
      </nav>
    );
  }
}

TopBarArticle.propTypes = {
  // showRefresh: PropTypes.bool,
  showNavigator: PropTypes.bool,
  isArticlePage: PropTypes.bool,
  userDetails: PropTypes.object,
  loadingState: PropTypes.object,
  toggleLeftBar: PropTypes.func,
  // settingsState: PropTypes.object,
}

// const mapStateToProps = (state) => {
//   return {
//     userDetails: state.userDetails,
//     loadingState: state.loadingState,
//     // settingsState: state.settingsState,
//     // loadingUserDetails: state.loadingState.loadingUserDetails,
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     toggleLeftBar: bindActionCreators(toggleLeftBar, dispatch)
//   }
// };

// export default connect(mapStateToProps, mapDispatchToProps)(TopBarArticle);
export default TopBarArticle;